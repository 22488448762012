<template>
  <div style="margin-top: 20px;">
    <!-- 巡检任务记录 -->
    <div class="box-card" style="text-align: left">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm" ref="queryFormRef"
        @submit.native.prevent>
        <el-form-item label="日期：">
          <el-date-picker v-model="date" type="daterange" value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="请选择开始日期"
            end-placeholder="请选择结束日期" @blur="onSubmit">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select popper-class="my-select" clearable v-model="queryForm.appoint_status" placeholder="请选择任务状态"
            @clear="queryForm.appoint_status = null" @change="onSubmit()">
            <!-- <el-option label="全部" :value="3"></el-option> -->
            <el-option label="待指派" :value="1"></el-option>
            <el-option label="已指派" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="异常说明：">
          <el-input maxlength="30" v-model="queryForm.exception_note" @keyup.enter.native="onSubmit()"
            @input="(e) => (queryForm.exception_note = validSpace(e))" placeholder="请输入异常说明"></el-input>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="巡检任务记录">
        <template slot="heard-right">
          <div class="right">
            <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('1f25f8cffbc741fcb72f8b50')">删除
            </lbButton>
            <lbButton type="setting" icon="shebeipeizhi" @click="openDefaultAppointDialog" v-if="this.getUserInfo.user_name != 'admin' &&
              isShowBtn('613a9829ad5e4a5aa23fad65')
              ">默认指派配置</lbButton>
            <lbButton icon="xinzeng" @click="openAppointDialog" v-if="this.getUserInfo.user_name != 'admin' &&
              isShowBtn('800a8c1ca9b84400bc16ce8b')
              ">指派</lbButton>
            <!-- <el-button size="mini" icon="iconfont lebo-chakan" type="success" @click="$router.push('/look-task-record/id')">查看</el-button> -->
          </div>
        </template>
        <div>
          <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
            <template slot-scope="{row}" slot="repairer_name">
              <span :style="row.is_appoint === 1 ? '' : 'color:#E6A23C;'">
                {{
                  row.is_appoint === 1
                  ? row.repairer_name
                  : "待指派"
                }}
              </span>
            </template>
            <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookTaskRecord(row.Id)"></lbButton>
            </template>
          </avue-crud> -->
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" header-align="center"></el-table-column>
            <el-table-column prop="exception_note" label="异常说明"></el-table-column>
            <el-table-column prop="inspection_name" label="检查项"></el-table-column>
            <el-table-column prop="project_name" label="资产项目"></el-table-column>
            <el-table-column prop="route_name" label="巡检路线"></el-table-column>
            <el-table-column prop="inspector_name" label="巡检人员"></el-table-column>
            <el-table-column label="维修人员">
              <template slot-scope="scope">
                <span :style="scope.row.is_appoint === 1 ? '' : 'color:#E6A23C;'">
                  {{
                    scope.row.is_appoint === 1
                    ? scope.row.repairer_name
                    : "待指派"
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="上报时间" width="200">
              <template slot-scope="scope">
                {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <lbButton type="succes" icon="chakan" hint="查看" @click="lookTaskRecord(scope.row.Id)"></lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </le-card>
    </div>
    <!-- 默认指派人员配置 -->
    <lebo-dialog title="默认指派配置" :isShow="isDefaultAppointDialog" width="45%" @close="defaultAppointDialogClose"
      footerSlot>
      <el-form :model="defaultAppointForm" :rules="defaultAppointFormRules" ref="defaultAppointFormRef"
        label-width="140px" class="default_appoint_form">
        <el-form-item prop="defaultAppointData" label-width="0" style="margin-bottom: 0px">
          <div class="content_box" v-for="(item, index) in defaultAppointForm.defaultAppointData" :key="index">
            <el-form-item label="巡检路线：" :prop="'defaultAppointData[' + index + '].route_id'" :rules="{
              required: true,
              message: '请选择巡检路线',
              trigger: 'blur',
            }">
              <el-select popper-class="my-select" clearable v-model="item.route_id" placeholder="请选择巡检路线"
                @change="routeChange" @focus="routeFocus(index)">
                <el-option v-for="i in routeSelectList" :key="i.Id" :label="i.route_name" :value="i.Id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="维修人员：" :prop="'defaultAppointData[' + index + '].repairer_id'" :rules="{
              required: true,
              message: '请选择维修人员',
              trigger: 'blur',
            }">
              <el-select popper-class="my-select" clearable v-model="item.repairer_id" placeholder="请选择维修人员">
                <el-option v-for="j in inspectorSelectList" :key="j.Id" :label="j.user_name" :value="j.Id"></el-option>
              </el-select>
            </el-form-item>
            <el-button style="height: 30px; margin-left: 15px; padding: 8px !important" icon="el-icon-plus" size="mini"
              v-if="index < allRouteSelectList.length - 1" circle @click="addDefaultAppoint(index)"></el-button>
            <el-button style="height: 30px; margin-left: 15px; padding: 8px !important" icon="el-icon-minus" size="mini"
              circle v-if="index > 0" @click="delDefaultAppoint(index)"></el-button>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <lbButton type="default" fill icon="confirm" @click="onSubmitDefaultAppoint()">保 存</lbButton>
      </span>
    </lebo-dialog>
    <!-- 修改指派人员 -->
    <lebo-dialog title="指派人员" :isShow="isAppointDialog" width="35%" class="appoint_dialog" @close="isAppointDialog = false"
      footerSlot>
      <el-form :model="appointForm" :rules="appointFormRules" ref="appointFormRef" label-width="150px"
        class="demo-ruleForm" v-if="isEditAppoint">
        <el-form-item label="当前维修人员：">{{ appointForm.name }}
        </el-form-item>
        <el-form-item label="修改维修人员：" prop="repairer_id">
          <el-select popper-class="my-select" clearable v-model="appointForm.repairer_id" placeholder="请选择维修人员"
            @change="inspectorChange">
            <el-option v-for="j in inspectorSelectList" :key="j.Id" :label="j.user_name" :value="j.Id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input type="textarea" maxlength="100" v-model.trim="appointForm.remark" :rows="4" placeholder="请输入备注" @blur="
            appointForm.remark = appointForm.remark
              .replace(/\r\n|\n|\r/g, '<br/>')
              .replace(/\s/g, '')
              .replace(/<br\/>/g, '\n')
            ">
          </el-input>
        </el-form-item>
      </el-form>
      <el-form :model="appointForm" :rules="appointFormRules" ref="appointFormRef" label-width="120px"
        class="demo-ruleForm" v-else>
        <el-form-item label="维修人员：" prop="repairer_id">
          <el-select popper-class="my-select" clearable v-model="appointForm.repairer_id" placeholder="请选择维修人员"
            @change="inspectorChange">
            <el-option v-for="j in inspectorSelectList" :key="j.Id" :label="j.user_name" :value="j.Id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <lebo-dialog title="提示信息" :isShow="isMsgDialog" :width="promptMessage.data.length > 0 ? '50%' : '30%'"
        @close="isMsgDialog = false" footerSlot append-to-body>
        <div v-if="promptMessage.data.length > 0">
          <div style="margin-bottom: 5px">{{ promptMessage.msg }}</div>
          <el-table ref="multipleTable" :data="promptMessage.data" tooltip-effect="dark" style="width: 100%" border
            stripe>
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column prop="exception_note" label="异常说明"></el-table-column>
            <el-table-column prop="inspection_name" label="检查项"></el-table-column>
            <el-table-column label="失败原因">该问题已处理完成，不可再次指派。</el-table-column>
          </el-table>
        </div>
        <p v-else>{{ promptMessage.msg }}</p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeMsgDialog">关 闭</el-button>
        </span>
      </lebo-dialog>
      <span slot="footer" class="dialog-footer">
        <lbButton type="default" fill icon="confirm" @click="onSubmitAppoint()">确认指派</lbButton>
      </span>
    </lebo-dialog>
    <!-- 查看巡检任务记录 -->
    <lebo-dialog title='查看巡检任务记录' :isShow="showLookTaskRecord" @close="showLookTaskRecord = false" footerSlot closeOnClickModals>
      <lookTaskRecord v-if="showLookTaskRecord" :id="dialogLookTaskRecordId" @closeDialog="closeInspectionTask">
      </lookTaskRecord>
    </lebo-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import {
  getTaskRecordList,
  delTaskRecord,
  setAppointOperate,
  getDefaultAppointList,
  getInspectorSelectList,
  getInspectionRouteSelectList,
  setDefaultAppoint
} from '@/api/assetsManagement'
import lookTaskRecord from './components/lookTaskRecord/index.vue'
export default {
  components: { lookTaskRecord },
  data () {
    return {
      date: '',
      // 查询表单
      queryForm: {
        start_time: '',
        end_time: '',
        // 指派状态 1：未指派 2：已指派 3：全部
        appoint_status: null,
        exception_note: '',
        PageSize: 10,
        PageIndex: 1
      },
      // 数据列表
      tableData: [],
      total: 0,
      // 选中的数据
      multipleSelection: [],
      // 是否展示默认指派巡检人员配置
      isDefaultAppointDialog: false,
      // 默认指派巡检人员配置表单
      defaultAppointForm: {
        defaultAppointData: [
          {
            route_id: '',
            repairer_id: ''
          }
        ]
      },
      // 默认指派巡检人员配置表单验证
      defaultAppointFormRules: {
        defaultAppointData: [
          {
            type: 'array',
            required: true,
            message: '请配置默认指派巡检人员',
            trigger: 'blur'
          }
        ]
      },
      // 巡检路线下拉列
      routeSelectList: [],
      // 巡检路线下拉列
      allRouteSelectList: [],
      // 巡检人员下拉列
      inspectorSelectList: [],
      // 指派巡检人员对话框
      isAppointDialog: false,
      // 是否是修改指派巡检人员
      isEditAppoint: false,
      appointForm: {
        Id: '',
        name: '',
        repairer_id: '',
        repairer_name: '',
        remark: ''
      },
      appointFormRules: {
        repairer_id: [
          { required: true, message: '请选择维修人员', trigger: 'blur' }
        ],
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }]
      },
      // 指派完成的提示信息
      isMsgDialog: false,
      promptMessage: {
        msg: '',
        data: []
      },
      showLookTaskRecord: false,
      dialogLookTaskRecordId: '',
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '异常说明',
            prop: 'exception_note'
          }, {
            label: '检查项',
            prop: 'inspection_name'
          }, {
            label: '资产项目',
            prop: 'project_name'
          }, {
            label: '巡检路线',
            prop: 'route_name'
          }, {
            label: '巡检人员',
            prop: 'inspector_name'
          }, {
            label: '维修人员',
            prop: 'repairer_name',
            slot: true
          }, {
            label: '上报时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetTaskRecordList()
  },
  mounted () {
    window.addEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['getUserInfo', 'getOptions', 'getWayName'])
  },
  watch: {
    date (newV, oldV) {
      if (newV) {
        this.queryForm.start_time = newV[0]
        this.queryForm.end_time = newV[1]
      } else {
        this.queryForm.start_time = ''
        this.queryForm.end_time = ''
        this.onSubmit()
      }
    }
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 获取巡检任务记录列表
    async fnGetTaskRecordList () {
      const res = await getTaskRecordList({
        start_time: this.queryForm.start_time,
        end_time: this.queryForm.end_time,
        appoint_status: this.queryForm.appoint_status
          ? this.queryForm.appoint_status
          : 3,
        exception_note: this.queryForm.exception_note,
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex
      })
      console.log(res)
      if (res && res.Code === 200 && res.Data) {
        this.tableData = res.Data.DataList
        this.total = res.Data.TotalCount
      } else {
        this.tableData = []
        this.total = 0
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetTaskRecordList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetTaskRecordList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetTaskRecordList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      //  // console.log(`当前页: ${val}`);
      this.fnGetTaskRecordList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetTaskRecordList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 点击删除按钮
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm(
          '此操作将永久删除选中的记录，您确定要删除选中的记录吗？',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
          .then(() => {
            var obj = {}
            var arr = []
            for (var i = 0; i < this.multipleSelection.length; i++) {
              arr.push(this.multipleSelection[i].Id)
              if (arr.length === this.multipleSelection.length) {
                obj.Id = arr.join(',')
                this.fndelTaskRecord(obj)
                // console.log('删除记录请求', obj);
              }
            }
          })
          .catch(() => {
            this.$msg.info('已取消删除')
          })
      } else {
        this.$msg.warning('请选择要删除的记录！')
      }
    },
    // 删除记录请求
    async fndelTaskRecord (obj) {
      const res = await delTaskRecord(obj)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetTaskRecordList()
    },
    // 展示默认指派巡检人员对话框
    openDefaultAppointDialog () {
      this.isDefaultAppointDialog = true
      var that = this
      axios
        .all([
          this.fnGetInspectorSelectList(),
          this.fnGetInspectionRouteSelectList(),
          this.fnGetDefaultAppointList()
        ])
        .then(
          axios.spread(function (person, route, appoint) {
            // console.log('获取巡检人员下拉列', person);
            that.inspectorSelectList =
              person && person.Code === 200 ? person.Data : []
            // console.log('获取巡检路线下拉列', route);
            that.allRouteSelectList =
              route && route.Code === 200 ? route.Data : []
            that.routeSelectList =
              route && route.Code === 200 ? route.Data : []
            // console.log('获取巡检任务异常默认指派记录', appoint);
            that.defaultAppointForm.defaultAppointData = []
            if (appoint && appoint.Code === 200 && appoint.Data.length > 0) {
              appoint.Data.forEach((item) => {
                var index = that.inspectorSelectList.findIndex(
                  (i) => i.Id === item.repairer_id
                )
                that.defaultAppointForm.defaultAppointData.push({
                  route_id: item.route_id,
                  repairer_id: index < 0 ? '' : item.repairer_id
                })
              })
              console.log(that.defaultAppointForm.defaultAppointData)
            } else {
              that.defaultAppointForm.defaultAppointData = [
                {
                  route_id: '',
                  repairer_id: ''
                }
              ]
            }
          })
        )
    },
    async fnGetInspectorSelectList () {
      return await getInspectorSelectList({
        person_type: 2 // 资产人员类型 巡检人员 = 1, 维修人员 = 2
      })
    },
    async fnGetInspectionRouteSelectList () {
      return await getInspectionRouteSelectList()
    },
    async fnGetDefaultAppointList () {
      return await getDefaultAppointList()
    },
    // 巡检路线下拉列发生改变时触发
    routeChange (val) {
      // console.log(val);
      var list = this.defaultAppointForm.defaultAppointData
      this.routeSelectList = this.allRouteSelectList.concat()
      for (var i = 0; i < list.length; i++) {
        this.routeSelectList = this.routeSelectList.filter((item) => {
          return item.Id !== list[i].route_id
        })
      }
    },
    // 巡检路线选择器获取焦点时触发
    routeFocus (index) {
      // console.log(index);
      var list = this.defaultAppointForm.defaultAppointData
      this.routeSelectList = this.allRouteSelectList.concat()
      for (var i = 0; i < list.length; i++) {
        this.routeSelectList = this.routeSelectList.filter((item) => {
          return item.Id !== list[i].route_id
        })
      }
      var arr = this.allRouteSelectList.filter((item) => {
        return item.Id === list[index].route_id
      })
      this.routeSelectList.push(...arr)
    },
    // 添加默认指派项
    addDefaultAppoint (index) {
      if (
        this.defaultAppointForm.defaultAppointData.length <
        this.allRouteSelectList.length
      ) {
        this.defaultAppointForm.defaultAppointData.push({
          route_id: '',
          repairer_id: ''
        })
      }
    },
    // 删除默认指派项
    delDefaultAppoint (index) {
      this.defaultAppointForm.defaultAppointData.splice(index, 1)
    },
    // 保存默认指派配置
    onSubmitDefaultAppoint () {
      this.$refs.defaultAppointFormRef.validate((valid) => {
        if (valid) {
          this.fnsetDefaultAppoint(this.defaultAppointForm)
        } else {
          return false
        }
      })
    },
    // 默认指派请求
    async fnsetDefaultAppoint (obj) {
      const res = await setDefaultAppoint(obj)
      console.log(res)
      if (res && res.Code === 200) {
        this.isDefaultAppointDialog = false
      }
      this.fnGetTaskRecordList()
    },
    // 默认指派对话框关闭时触发
    defaultAppointDialogClose () {
      this.isDefaultAppointDialog = false
      // this.$refs.defaultAppointFormRef.resetFields();
      this.defaultAppointForm.defaultAppointData = [
        {
          route_id: '',
          repairer_id: ''
        }
      ]
      // console.log(this.defaultAppointForm);
    },
    // 展示指派巡检人员对话框
    openAppointDialog () {
      if (this.multipleSelection.length > 0) {
        this.isAppointDialog = true
        var nameArr = []
        var idArr = []
        var personIdArr = []
        // console.log(this.multipleSelection);
        this.multipleSelection.forEach((item) => {
          if (item.is_appoint === 1) {
            // 已指派
            nameArr.push(item.repairer_name)
            personIdArr.push(item.repairer_id)
          }
          idArr.push(item.Id)
        })
        var that = this
        axios.all([this.fnGetInspectorSelectList()]).then(
          axios.spread(function (person) {
            that.inspectorSelectList =
              person && person.Code === 200 ? person.Data : []
            personIdArr.forEach((k) => {
              that.inspectorSelectList = that.inspectorSelectList.filter(
                (j) => {
                  return j.Id !== k
                }
              )
            })
          })
        )
        this.$nextTick(() => {
          this.appointForm.Id = idArr.join(',')
          this.appointForm.name = [...new Set(nameArr)].join('、')
        })
        this.isEditAppoint = nameArr.length > 0
      } else {
        this.$msg.warning('请选择要指派的任务！')
      }
    },
    // 巡检人员下拉列发生改变时触发
    inspectorChange (val) {
      console.log(val)
      var arr = this.inspectorSelectList.filter((item) => {
        return item.Id === val
      })
      this.appointForm.repairer_name = arr[0].user_name
    },
    // 保存指派人员配置
    onSubmitAppoint () {
      this.$refs.appointFormRef.validate((valid) => {
        if (valid) {
          this.fnSetAppointOperate({
            Id: this.appointForm.Id,
            repairer_id: this.appointForm.repairer_id,
            repairer_name: this.appointForm.repairer_name,
            remark: this.appointForm.remark
          })
        } else {
          return false
        }
      })
    },
    // 指派任务请求
    async fnSetAppointOperate (obj) {
      const res = await setAppointOperate(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        if (this.isEditAppoint) {
          this.isMsgDialog = true
          this.promptMessage.msg = res.Message
          this.promptMessage.data = res.Data
        } else {
          this.isAppointDialog = false
          this.fnGetTaskRecordList()
        }
      }
    },
    // 关闭对话框
    closeMsgDialog () {
      this.$refs.appointFormRef.resetFields()
      this.appointForm.Id = ''
      this.appointForm.name = ''
      this.appointForm.repairer_id = ''
      this.appointForm.repairer_name = ''
      this.appointForm.remark = ''
      this.isAppointDialog = false
      this.isEditAppoint = false
      this.isMsgDialog = false
      this.fnGetTaskRecordList()
    },
    lookTaskRecord (id) {
      this.dialogLookTaskRecordId = id
      this.showLookTaskRecord = true
    },
    closeInspectionTask () {
      this.showLookTaskRecord = false
      this.fnGetTaskRecordList()
    }
  }
}
</script>

<style scoped lang="less">
.default_appoint_form {
  .content_box {
    display: flex;
    align-items: center;
    margin-bottom: 22px;

    .el-select {
      width: 100%;
    }
  }
}

.appoint_dialog {
  /deep/ .el-dialog__body {
    padding: 30px;

    .el-textarea,
    .el-select {
      width: 100%;
    }
  }
}
</style>
