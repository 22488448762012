<template>
    <!-- 查看巡检任务记录 -->
    <div>
      <div class="box-card" style="text-align: left">
        <div class="title">异常详情</div>
        <el-divider></el-divider>
        <div class="detail_content">
          <div class="left_box">
            <el-form label-width="130px" class="detailForm">
              <el-form-item label="巡检项目：">{{
                detailForm.project_name
              }}</el-form-item>
              <el-form-item label="巡检路线：">{{
                detailForm.route_name
              }}</el-form-item>
              <el-form-item label="巡检人员：">{{
                detailForm.inspector_name
              }}</el-form-item>
              <el-form-item label="上报时间："
                >{{
                  $moment(detailForm.create_time).format("YYYY-MM-DD HH:mm:ss")
                }}
              </el-form-item>
              <el-form-item label="检查项：">{{
                detailForm.inspection_name
              }}</el-form-item>
              <el-form-item label="异常说明：">{{
                detailForm.exception_note
              }}</el-form-item>
              <el-form-item label="状态：" v-if="detailForm.is_appoint == 1">
                <span class="plan_status_box">已指派</span>
              </el-form-item>
            </el-form>
          </div>
          <div class="right_box">
            <el-image
              class="elImage"
              v-for="(item, index) in detailForm.pic"
              :key="index"
              :src="item"
              :preview-src-list="detailForm.pic"
              :z-index="9999"
            >
              <div slot="error" class="image-slot">
                <i class="iconfont lebo-picerr"></i>
                <span>加载失败</span>
              </div>
            </el-image>
          </div>
        </div>
        <div class="title" v-if="detailForm.is_appoint == 1">指派记录</div>
        <el-divider v-if="detailForm.is_appoint == 1"></el-divider>
        <el-table
          ref="multipleTable"
          :data="dataList"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 15px"
          height="200"
          border
          stripe
          v-if="detailForm.is_appoint == 1"
        >
          <el-table-column prop="repairer_name" label="维修人员">
          </el-table-column>
          <el-table-column prop="create_time" label="指派时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column prop="create_name" label="平台账号"></el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
        </el-table>
        <!-- <lbButton type="goBack" icon="back" class="checkButton2"  @click="defaultForm" v-if="detailForm.is_appoint != 1">返 回</lbButton> -->
            <lbButton type="default" fill icon="confirm" class="checkButton"  v-if="detailForm.is_appoint != 1"
              @click="openAppointDialog" >指 派</lbButton>
      </div>
      <!-- 修改指派巡检人员 -->
      <lebo-dialog
        title="指派人员"
        :isShow="isAppointDialog"
        width="25%"
        class="appoint_dialog" @close="isAppointDialog=false" footerSlot>
        <el-form
          :model="appointForm"
          :rules="appointFormRules"
          ref="appointFormRef"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item label="维修人员：" prop="repairer_id">
            <el-select
              popper-class="my-select"
              clearable
              v-model="appointForm.repairer_id"
              placeholder="请选择维修人员"
              @change="inspectorChange"
              style="width: 100%"
            >
              <el-option
                v-for="j in inspectorSelectList"
                :key="j.Id"
                :label="j.user_name"
                :value="j.Id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <!-- <lbButton type="goBack" icon="back" @click="isAppointDialog = false">返 回</lbButton> -->
          <lbButton type="default" fill icon="confirm"  @click="onSubmitAppoint()">确认指派</lbButton>
        </span>
      </lebo-dialog>
    </div>
  </template>

<script>
import {
  getTaskRecordDetail,
  getExceptionAppointList,
  getInspectorSelectList,
  setAppointOperate
} from '@/api/assetsManagement'
import { mapMutations } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      detailForm: {},
      dataList: [],
      // 指派巡检人员对话框
      isAppointDialog: false,
      inspectorSelectList: [],
      appointForm: {
        repairer_id: '',
        insprepairer_name: ''
      },
      appointFormRules: {
        repairer_id: [
          { required: true, message: '请选择维修人员', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.fnGetTaskRecordDetail()
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 获取数据详情
    async fnGetTaskRecordDetail () {
      const res = await getTaskRecordDetail({
        Id: this.id
      })
      if (res && res.Code === 200) {
        this.detailForm = res.Data[0]
        this.detailForm.pic = this.detailForm.exception_pic.split(',')
        if (this.detailForm.is_appoint === 1) {
          // 获取指派记录
          this.fnGetExceptionAppointList()
        }
      }
    },
    // 获取指派记录
    async fnGetExceptionAppointList () {
      const res = await getExceptionAppointList({
        Id: this.id
      })
      if (res && res.Code === 200) {
        this.dataList = res.Data
      }
    },
    // 展示指派巡检人员对话框
    openAppointDialog () {
      this.fnGetInspectorSelectList()
      this.isAppointDialog = true
    },
    async fnGetInspectorSelectList () {
      const res = await getInspectorSelectList({
        person_type: 2 // 资产人员类型 巡检人员 = 1, 维修人员 = 2
      })
      // console.log('获取巡检人员下拉列', res);
      this.inspectorSelectList = res && res.Code === 200 ? res.Data : []
    },
    // 巡检人员下拉列发生改变时触发
    inspectorChange (val) {
      // console.log(val);
      var arr = this.inspectorSelectList.filter((item) => {
        return item.Id === val
      })
      this.appointForm.repairer_name = arr[0].user_name
    },
    // 保存指派人员配置
    onSubmitAppoint () {
      this.$refs.appointFormRef.validate((valid) => {
        if (valid) {
          this.fnSetAppointOperate({
            Id: this.id,
            repairer_id: this.appointForm.repairer_id,
            repairer_name: this.appointForm.repairer_name
          })
        } else {
          return false
        }
      })
    },
    // 指派任务请求
    async fnSetAppointOperate (obj) {
      const res = await setAppointOperate(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        this.isAppointDialog = false
        this.fnGetTaskRecordDetail()
      }
    },
    // 返回
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }
}
</script>

  <style scoped lang="less">
  .title {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: #000;
  }

  .detail_content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;

    .left_box {
      width: 75%;
      .detailForm {
        /deep/ .el-form-item {
          margin-bottom: 0;
        }
      }
    }

    .right_box {
      // width: 25%;
      text-align: right;
      display: flex;
      // justify-content: end;
    }
  }

  .plan_status_box {
    padding: 5px 10px;
    color: #fff;
    background-color: #67c23a;
  }

  .elImage {
    width: 120px;
    height: 180px;
    margin-left: 15px;

    /deep/ .image-slot {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #f5f7fa;
      color: #999;
      .iconfont{
        font-size: 36px;
      }
    }

    /deep/ .el-image__inner {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }
  .box-card {
    position: relative;
    padding-bottom: 30px;

    .checkButton {
        position: absolute;
        right: 0px;
    }

    .checkButton2 {
        position: absolute;
        right: 100px;
    }
}
  </style>
